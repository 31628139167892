@import '@root/_variables';

.simple_title {
    font-weight: 400;
    font-family: $font-prompt;
}

.simple_title.light,
.simple_subtitle.light {
    font-weight: 200;
}

.simple_subtitle {
    margin: 0px;
    font-weight: $font-weight-prompt;
    font-family: $font-prompt;
}