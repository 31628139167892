@import '@root/_variables';

.content.couch {
    width: 100%;
    .left {
        order: 1;

        .simple_subtitle {
            margin-bottom: 20px;
        }

        .symbol.dash {
            width: 115px;
            height: 19px;
        }
    }

    .right {
        order: 2;
        max-width: 768px;

        .symbol.star {
            width: 63px;
            height: 58px;
        }

        .symbol.two_points {
            width: 24px;
            height: 120px;
        }

    }
}