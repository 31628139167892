@import '@root/_variables';

.tooltip_mobile {
    top: calc(100vh - 120px);
    position: fixed;
    width: 100%;
    max-width: 100%;
    border: 0px;
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: 0.6s;
    -webkit-transition: 0.6s;
    background-color: $white;

    &.loaded {
        top: calc(100vh - 382px);
        z-index: 2;
        transition: 0.6s;
        -webkit-transition: 0.6s;
    }
}
  
.tooltip {
    display: none;
    max-width: 320px;
    background: #FFFFFF;
    border: 1px solid #DCDFE2;
    color: #000000;
    font-weight: bold;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 8%);
    font-size: 14px;
    line-height: 18px;
    cursor: default;
    pointer-events: auto !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -khtml-user-select: none;
    -webkit-touch-callout: none;
    z-index: 9999 !important;

    &.mobile {
        max-width: 1920px;

        ul.replacements {

            li.replacement.mobile {
                height: 60px;
                line-height: 40px;
                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.tooltip_mobile {
    ul.replacements {
        max-height: 420px;
        overflow-y: auto;
    }
}

.tooltip,
.tooltip_mobile {
    &.mobile {
        max-width: 1920px;

        ul.replacements {

            li.replacement.mobile {
                height: 60px;
                line-height: 40px;
                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.tooltip_mobile {
    ul.replacements {
        max-height: 420px;
        overflow-y: auto;
    }
}

.tooltip,
.tooltip_mobile {
    ul.replacements {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout: none;
        padding-inline-start: 0;
        list-style: none;
        z-index: 999;
        padding: 0;
        margin: 0;
        width: auto;
  
        .symbol.feedback {
            margin-top: -3px;
            width: 16px;
            height: 16px;
  
            svg {
                fill: var(--base-color);
            }
        }
  
        .symbol.question {
            margin-top: -2px;
            width: 50px;
            height: 34px;
        }
  
        .symbol.ignore {
            margin-top: -3px;
            width: 16px;
            height: 16px;
  
            svg {
                fill: $ignore-color;
            }
        }
    }
}

.tooltip_mobile {
    ul.replacements {
        .symbol.feedback {
            margin-top: 13px;
        }

        .symbol.ignore {
            margin-top: 13px;
        }
    }
}