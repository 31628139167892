@import '@root/_variables';

.footer>* {
    flex: 1 100%;
}

.footer {
    width: 100%;
    flex-flow: row wrap;
    border-top: 1px solid var(--base-color);
    background-color: $white;

    .footer_logo {
        flex: 0 150px;

        svg {
            width: 76px;
            height: 66px;
        }
    }

    .footer_navigation {
        ul {
            list-style: none;

            li {
                width: 100px;
                height: 15px;
                background-color: $transparent;
                font-family: $font-prompt;
                font-weight: $font-weight-prompt;
                color: $text-color;
                text-align: left;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }
    }

    .footer_copyright {
        p {
            height: 15px;
            background-color: $transparent;
            font-family: $font-prompt;
            color: $text-color;
            text-align: left;
        }
    }
}