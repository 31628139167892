@import '@root/_variables';

.content>* {
    flex: 1 100%;
}

.content.draft {

    background: $landing-background;
    width: 100%;


    .left {
        order: 1;

        .simple_title:nth-child(2) {
            margin-bottom: 20px;
        }

        .subtitle {
            font-family: $font-prompt;
            font-size: 14px;
            color: var(--base-text-color);
            margin-bottom: 30px;

            svg {
                fill: var(--base-text-color);
            }
        }
    }

    .right {
        order: 2;

        .mini_editor {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
            background-color: $white;

            textarea {
                outline: none;
                user-select: text;
                white-space: pre-wrap;
                overflow-wrap: break-word;
            }

            .bottom {
                background-color: $landing-background;

                .symbol.text {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}