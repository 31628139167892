@import './_mixins.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @include theme-aware('--base-color', '--base-color');
    @include theme-aware('--base-color-hover', '--base-color-hover');
    @include theme-aware('--base-text-color', '--base-text-color');
}

:root {
    --base-color: var($base-color);
    --base-color-hover: var($base-color-hover);
    --base-text-color: var($base-text-color);
}

div#finnit {
    margin: 0 auto;
    display: block;
}