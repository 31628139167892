@import '@root/_variables';

.content.simple_gendern {
    .left {
        order: 1;

        .simple_subtitle {
            margin-bottom: 20px;
        }

        .symbol.dash {
            width: 93px;
            height: 15px;
        }
    }

    .right {
        order: 2;

        .finnit_square {
            background-color: $square-background;
        }

        .image.office {
            img {
                width: auto;
                height: auto;
            }
        }

        .symbol.dash {
            width: 93px;
            height: 15px;
        }

        .sub {
            .symbol.star {
                width: 63px;
                height: 58px;
                margin-top: 16px;
                margin-left: 47px;
            }

            .image.woman {
                margin-top: 20px;
                margin-left: 31px;

                img {
                    width: auto;
                    height: auto;
                }
            }

            .symbol.two_points {
                width: 24px;
                height: 120px;
            }

            .symbol.checks_upside_down {
                width: auto;
                height: auto;
            }
        }

    }
}