@import '@root/_variables';

.simple_text {
    white-space: pre-line;
    font-family: $font-murecho;
    font-weight: $font-weight-murecho;
    margin: 0px;
    line-height: 27px;
    margin-bottom: 10px;

    ul {
        margin-top: 5px;

        li {
            margin-top: -15px;
        }
    }
}

.simple_text.quotes {
    font-family: $font-merriweather;
    font-weight: 300;
    color: $quotes-color;
    font-style: italic;
}