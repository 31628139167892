@import '@root/_variables';

.content.editor {
    
    width: 100%;

    .left {
        order: 1;
    }

    .right {
        order: 2;

        .image {
            border-radius: 20px;
            background-color: $white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);

        }
    }
}