@import '@root/_variables';

.modal {
    transition: opacity 0.25s ease;

    .modal-content {
        .simple_subtitle {
            color: var(--base-color);
        }

        .symbol.feedback {
            width: 28px;
            height: 28px;

            svg {
                fill: var(--base-color);
            }
        }

        .symbol.plus,
        .symbol.minus {
            width: 40px;
            height: 40px;

        }
    }
}

.category_input {
    outline: none;
}

.message_input,
.repl_input {
    outline: none;
    user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 27px;
    font-size: 14px;
    font-family: $font-murecho;
    border: 1px solid $border-white;
    padding: 6px 7px;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
    color: $text-color;
}

.message_input {
    min-height: 125px;

    &::placeholder {
        color: $contact_form-placeholder-color
    }
}