@import url('https://static.b-ite.com/fonts/murecho/4/fonts.css');
@import url('https://static.b-ite.com/fonts/prompt/9/fonts.css');
@import url('https://static.b-ite.com/fonts/merriweather/24/fonts.css');

@import '@root/_variables';

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-family: $font-murecho;
  margin: 0;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.wrapper {
  flex-flow: row wrap;
}

.wrapper>* {
  flex: 1 100%;
}

.black {
  color: $black;
}

.base-color {
  color: var(--base-color) !important;
}

.text-color {
  color: var(--base-text-color);
}

.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;

  &:hover {
    opacity: 0.6 !important;
  }
}

.button {
  opacity: 0.8;
  padding: 12px 40px;
  border-radius: 10px;
  font-family: $font-prompt;
  font-weight: $font-weight-prompt;
  text-align: center;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 1.0;
  }

  &.base {
    border: 2px solid var(--base-color);
    background-color: var(--base-color);
    color: $white;

    &:hover {
      background-color: var(--base-color);
    }
  }

  &.base-border {
    border: 2px solid var(--base-color);
    color: var(--base-color);
  }

  &.base-background {
    border: 2px solid $base-color-background;
    background-color: $base-color-background;
    color: $white;
  }

  &.small {
    padding: 4px 12px;
    font-size: 11px;
  }

  &.tiny {
    height: 30px;
    width: 30px;
    padding: 1px 1px;
    font-size: 11px;
  }

  &.squared {
    border-radius: 0 !important;
  }

  &.white {
    border: 2px solid var(--base-text-color);
    background-color: $white;

    &:hover {
      opacity: 1.0;
    }
  }

  &.grey {
    border: 2px solid $slitegrey;
    background-color: $slitegrey;

    &:hover {
      opacity: 1.0;
      border: 2px solid $grey;
      background-color: $grey;
    }
  }
}

.pulse:active,
.pulse:focus {
  transform: scale(1.5);
  box-shadow: 0px 10px 20px 2px $grey;
  border-radius: 5px;
  border: 1px solid $grey;
  padding: 4px;
}

.badge {
  opacity: 0.8;
  padding: 12px 40px;
  border-radius: 10px;
  font-family: $font-prompt;
  font-weight: $font-weight-prompt;
  text-align: center;
  font-size: 13px;

  &.base {
    border-radius: 10px;
    background-color: $correct-gendered-green;
  }
}

.shadow_box {
  box-sizing: border-box;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
}

.no_shadow {
  box-sizing: border-box;
  box-shadow: none;
}

.overflow_scroll {
  overflow: auto;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  scrollbar-width: thick;
  scrollbar-color: var(--base-color) $transparent;
  list-style: none;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #D2F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--base-color);
    border-radius: 6px;
    border: 3px solid $transparent;
  }
}

svg {
  fill: var(--base-color);
}

a:hover {
  color: var(--base-color);
}