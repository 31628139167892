@import '@root/_variables';

.content.editor_mini {
    .left {
        order: 1;

        .image {
            border-radius: 45px;
            background-color: $white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
        }
    }
}