@import '@root/_variables';

ul.replacements {
    li.replacement {
        list-style: none;
        display: block;
        text-align: left;
        padding: 10px 7px;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout: none;
        font-family: $font-murecho;
        font-weight: $font-weight-murecho;
        font-size: 11px;
        background-color: $correct-gendered-green;
        opacity: 0.8;
        height: 30px;
        line-height: 8px;
        &.in_tooltip {
            background-color: $white;
        }
        &:after {
            content: "\A";
            white-space: pre;
        }
        &:hover {
            cursor: pointer;
            background-color: $correct-gendered-green;
            opacity: 1.0;
        }
    }

    li.inactive {
        display: none;
    }

    hr {
        margin: 0px;
        margin-left: 20px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}