@import '@root/_variables';

.content.editor_reverted {
    background-color: $landing-background;

    .right {
        order: 2;
        flex: 0 426px;

        .action {
            .button:nth-child(2) {
                margin-left: 18px;
            }
        }

        .text {
            background-color: $transparent;
            font-family: $font-murecho;
            font-weight: $font-weight-murecho;
            color: $text-color;
            text-align: left;
            line-height: 27px;
            font-size: 15px;
        }

        .title {
            font-family: $font-prompt;
            font-weight: $font-weight-prompt-title;
            font-size: 24px;
            display: block;
            line-height: 45px;
            margin-bottom: 25px;
        }

        .subtitle {
            color: $base-color;
            background-color: $transparent;
            box-sizing: border-box;
            font-family: $font-prompt;
            font-weight: $font-weight-prompt;
            font-size: 14px;
            text-align: left;
            line-height: normal;
            margin-bottom: 10px;

            svg {
                width: 14px;
                height: 14px;
                box-sizing: border-box;
                margin-right: 15px;
            }
        }
    }

    .left {
        order: 1;
        flex: 0 570px;

        .symbol.dash {
            width: 115px;
            height: 19px;
        }

        .image {
            padding: 2px;
            border-radius: 20px;
            background-color: $white;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
            position: relative;
        }
    }

    .action {
        .button:nth-child(2) {
            margin-left: 18px;
        }
    }
}