@import '@root/_variables';

.teaser {
  width: 100%;
  background-color: $landing-background;

  .left {

    .symbol.dash {
      width: 93px;
      height: 16px;

      svg {
        fill: var(--base-text-color);
      }
    }

    .symbol.star {
      width: 58px;
      height: 54px;

      svg {
        fill: var(--base-text-color);
      }
    }

    .symbol.two_points {
      width: 19px;
      height: 97px;

      svg {
        fill: var(--base-color);
      }
    }
  }

  .right {
    .symbol.dash {
      width: 93px;
      height: 16px;

      svg {
        fill: var(--base-text-color);
      }
    }

    .symbol.star {
      width: 44px;
      height: 41px;

      svg {
        fill: var(--base-color);
      }

      &.black {
        width: 58px;
        height: 54px;
    
        svg {
          fill: var(--base-text-color);
        }
      }
    }
  }

  .title {
    height: 135px;
    font-family: $font-prompt;
    font-weight: $font-weight-prompt-title;
    color: $text-color;
    line-height: 45px;
  }
}