@import '@root/_variables';

.content.office_mini {

    .left {
        order: 2;

        .symbol.star {
            width: 90px;
            height: 83px;

            svg {
                fill: var(--base-color);
            }
        }

        .symbol.two_points {
            width: 24px;
            height: 120px;
        }

        .symbol.checks_upside_down {
            width: 265px;
            height: 239px;
        }

        .finnit_square {
            background-color: $square-background;
        }
    }

    .right {
        background-color: $white;
        order: 1;

        .simple_subtitle {
            margin-bottom: 10px;
        }

        .symbol.nodes {
            width: 71px;
            height: 44px;

            svg {
                fill: var(--base-color);
            }
        }
    }
}