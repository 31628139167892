@import '@root/_variables';

.editor_footer {
  background-color: var(--base-color);
  font-size: 13px;
  bottom: 0;
  width: 100%;
  // -webkit-box-shadow: 0 -1px 5px 0 var(--base-text-color);
  // -moz-box-shadow: 0 -1px 5px 0 var(--base-text-color);
  // box-shadow: 0 -1px 5px 0 var(--base-text-color);

  ul {
    li {
      a {
        color: $white;
      }
    }
  }

  .theme_mode {
    box-sizing: border-box;
    font-family: $font-prompt;
    color: $white;
    text-align: center;
  }

  .standard {
    background-color: $base-color;
  }

  .high_contrast {
    background-color: $high-contrast-theme;
  }

}