@import '@root/_variables';

.content>* {
    flex: 1 100%;
}

.content.gendern {

    .left {
        order: 1;

        .simple_subtitle {
            margin-bottom: 20px;
        }
    }

    .right {
        order: 2;
        max-width: 768px;

        .finnit_square {
            width: 172px;
            height: 602px;
            padding: 2px;
            background-color: $square-background;
        }

        .sub {
            display: flex;

            .sub_left {
                position: relative;
                display: flex;
                flex-flow: column;

                .image {
                    display: flex;

                    img {
                        width: 360px;
                        height: 504px;
                    }
                }

            }

        }


    }

    .symbol.star {
        width: 60px;
        height: 56px;
    }

    .symbol.two_points {
        width: 19px;
        height: 97px;
    }

    .symbol.dash {
        width: 93px;
        height: 15px;
    }
}