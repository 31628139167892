// colors
$white: #ffffff;
$black: #000000;
$lightgreen: lightgreen;
$lightgrey: rgba(242, 242, 242, 0.98);
$slitegrey: #efefef;
$grey: #dfdfde;

$body-background: #fafafa;

$transparent: rgba(255, 255, 255, 0);

$base-color: #33cccc;
$base-color-hover: #2edcae;
$base-color-background: #d6f5f5;
$base-text-color: #3f3f3f;

$base-color-high-contrast: #097f8e;
$base-color-high-contrast-hover: #086b78;
$base-text-color-high-contrast: #ffffff;

$replacement-hover-color: #d6f5f5;

$text-color: #3f3f3f;
$quotes-color: #126361;
$placeholder-color: #d7d7d7;
$contact_form-placeholder-color: #999999;

$hit-list-color: rgba(242, 242, 242, 0.98);

$border-white: #e5e7e9;

$square-background: rgba(51, 204, 204, 0.2);
$teaser-background: #d6f5f5;
$landing-background: #e8f0f0;

$wrong-gendered-red: #ff8f33;
$wrong-gendered-border: #ff8f33;
$wrong-gendered-hover: rgba(255, 143, 51, 0.2980392156862745);

$not-gendered-orange: #f1d302;
$not-gendered-border: #f1d302;
$not-gendered-hover: rgba(241, 211, 2, 0.2980392156862745);

$correct-gendered-green: #d6f5f5;
$correct-gendered-border: #d6f5f5;
$correct-gendered-hover: rgba(214, 245, 245, 1);

$ignore-color: #fca0b5;

$high-contrast-theme: #3f3f3f;

// sizes
$header-height: 77px;

// fonts
$font-prompt: "Prompt-Light",
"Prompt Light",
"Prompt",
sans-serif;
$font-weight-prompt: 400;
$font-weight-prompt-title: 200;

$font-murecho: "Murecho-Regular",
"Murecho",
sans-serif;
$font-weight-murecho: 400;

$font-merriweather: "Merriweather-LightItalic",
"Merriweather Light Italic",
"Merriweather",
sans-serif;
$font-weight-merriweather: 400;