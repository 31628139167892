@import '@root/_variables';

.left_detail {
    .hit_detail {
        font-family: $font-murecho;
        color: $text-color;
        font-size: 12px;

        .simple_text {
            font-size: 12px !important;
        }

        .hit_level_detail_word {
            overflow: hidden;
            transition: background 150ms ease 0ms, border-color 150ms ease 0ms, box-shadow 150ms ease 0ms;
            background: var(--base-color);
            border: 1px solid var(--base-color);
            color: $white;
            box-shadow: none;
            padding: 6px 12px;
            border-radius: 5px;
            /* white-space: break-spaces; */
        }

        .hit_level_detail {
            display: inline-block;
        }

        .positive,
        .correct {
            border-bottom: 2px solid $correct-gendered-border;
            background-color: $correct-gendered-green;
        }

        .not {
            border-bottom: 2px solid $not-gendered-border;
            background-color: $not-gendered-orange;
        }

        .wrong {
            border-bottom: 2px solid $wrong-gendered-border;
            background-color: $wrong-gendered-red;
        }

        .symbol.feedback {
            width: 16px;
            height: 16px;

            svg {
                fill: var(--base-color);
            }
        }

        .symbol.ignore {
            width: 16px;
            height: 16px;

            svg {
                fill: $ignore-color;
            }
        }
    }

    .hit_detail.subtitle {
        background-color: $transparent;
        font-family: $font-murecho;
        font-weight: 500;
        color: var(--base-color);
        text-align: left;
        line-height: 30px;
    }
}