@import '@root/_variables';

.contact_page_div {
    .background {
        background-color: $landing-background;
    }


    .left {
        .symbol.dash:nth-child(1) {
            svg {
                fill: var(--base-text-color);
            }
        }

        .white_background {
            svg {
                width: 616px;
                height: 316px;
            }
        }

        .symbol.star {
            width: 81px;
            height: 75px;

            svg {
                fill: var(--base-text-color);
            }
        }

        .symbol.two_points {
            width: 21px;
            height: 106px;
        }

        .symbol.dash {
            width: 124px;
            height: 21px;
        }
    }

    .right {

        .symbol.star {
            width: 81px;
            height: 75px;
        }

        .symbol.two_points {
            width: 21px;
            height: 106px;
        }

        .symbol.dash {
            width: 124px;
            height: 21px;

            svg {
                fill: var(--base-text-color);
            }
        }

        textarea.message,
        input.contact_input,
        select.contact_select {
            font-family: $font-murecho;
            color: $text-color;
            outline: none;
            user-select: text;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }

        input.contact_input,
        select.contact_select {
            height: 39px;
            padding: 0px 2px 2px 10px;
            margin-bottom: 25px;
        }

        textarea.message::placeholder,
        input.contact_input::placeholder {
            color: $contact_form-placeholder-color;
        }

        textarea.message {
            height: 286px;
            padding: 15px 10px 10px 10px;

        }

        input[type="checkbox"] {
            height: 20px;
            width: 20px;
            accent-color: var(--base-color);
        }

        label {
            font-size: 12px;
            font-family: $font-murecho;
        }

        select.contact_select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            // Additional resets for further consistency
            background-color: transparent;
            margin: 0;
            width: 100%;
            position: relative;
            color: $white;
            padding: 4px;
            padding-left: 10px;

            option {
                color: var(--base-text-color);
            }

            option:hover {
                background-color: var(--base-color);
            }

            &::-ms-expand {
                display: none;
                grid-area: select;
            }

            &:focus+&.focus {
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                color: var(--base-text-color);
                border-radius: inherit;
                border: 2px solid var(red);

                option:checked {
                    background-color: var(--base-color);
                }
            }
        }

        div.contact_select_div {
            width: 130px;
            cursor: pointer;
            grid-template-areas: "select";
            align-items: center;
            background-color: var(--base-color);
            color: $white;
        }

        .symbol.caret {
            width: 6px;
            height: 10px;

            svg {
                fill: $white;
            }

            .closed {
                transform: rotate(90deg);
                transition: 0.3s;
            }

            .open {
                transform: rotate(270deg);
                transition: 0.3s;
            }
        }

    }
}