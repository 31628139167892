@import '@root/_variables';

.content.draft_mini {

    background: $landing-background;


    .left {
        order: 2;
        background-color: $white;
    }

    .right {
        order: 1;

        .title_container {
            width: 100%;
            background-color: rgba(51, 204, 204, 0.4);
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
        
            .title.white {
                color: $white;
            }

            .subtitle {
                font-family: $font-prompt;
                font-size: 14px;
                margin-bottom: 30px;

                svg {
                    fill: $white;
                }
            }
            .subtitle.white {
                color: $white;
            }
        }
    }
}