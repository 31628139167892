@import '@root/_variables';

.content.motivation {
    background-color: $landing-background;

    .left {
        order: 1;
        flex: 0 570px;

        .symbol.dash {
            width: 124px;
            height: 21px;

            svg {
                fill: $base-text-color;
            }
        }

        .symbol.star {
            width: 81px;
            height: 75px;
            bottom: 0;

            svg {
                fill: $base-text-color;
            }
        }

        .symbol.quotes_up,
        .symbol.quotes_down {
            width: 42px;
            height: 36px;

            svg {
                fill: var(--base-color);
            }
        }
    }

    .right {
        order: 2;

        .symbol.two_points {
            width: 24px;
            height: 120px;

            svg {
                fill: var(--base-color);
            }
        }

        .symbol.star {
            width: 63px;
            height: 58px;

            svg {
                fill: var(--base-color);
            }
        }
    }
}