.dots_bounce {
    .circle-line {
        z-index: 9999;
        width: 200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;


        .circle {
            position: relative;
            width: 5px;
            height: 5px;
            border-radius: 30px;
            margin: 5px;
            background-color: var(--base-color);

            &:nth-child(1) {
                animation: bounce 0.5s infinite alternate;
            }

            &:nth-child(2) {
                animation: bounce 0.5s 0.2s infinite alternate;
            }

            &:nth-child(3) {
                animation: bounce 0.5s .5s infinite alternate;
            }
    
        }
    }
}

@keyframes bounce {
    from {

        top: 5px;

    }

    to {

        top: -5px;
    }
}