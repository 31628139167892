@import '@root/_variables';

.box {
    border: 1px solid var(--base-color);
    background: var(--base-color);
    border-radius: 5px;
    color: $white;
    padding: 0px 12px;
    svg {
        fill: $white;
    }

    &.loading {
        background: var(--base-color);

        .circle {
            background-color: $white !important;
        }
    }
}