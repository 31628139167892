@import '@root/_variables';

.data_privacy_page_div {
    .content:nth-child(2n) {
        background-color: $landing-background;
    }

    .upside_down_checks {
        position: relative; 
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .disclosure_box {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
        }

        .disclosure_text {
            word-spacing: 6px;
            font-family: $font-murecho;
            font-size: 15px;
            line-height: 27px;
        }

        &::before {
            content: "";
            position: absolute;
            background-image: url('@assets/img/upside_down_checks.png');
            background-size: cover;
            opacity: 0.2;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    }
}