@import '@root/_variables';

.imprint_page_div {
    .content {
        background-color: $landing-background;
        .right {
            .white_background {
                svg {
                    width: 728px;
                    height: 374px;
                }
            }
        }

        .symbol.two_points {
            width: 21px;
            height: 106px;
        }

        .symbol.dash {
            width: 124px;
            height: 21px;

            &.black  {
                svg {
                    fill: var(--base-text-color);
                }
            }
        }

        .symbol.star {
            width: 58px;
            height: 54px;

            &.black {
                svg {
                    fill: var(--base-text-color);
                }
            }
        }

        .left {
            .symbol.star {
                width: 81px;
                height: 75px;
            }
        }
    }
}