@import '@root/_variables';

.editor_page_div {
  flex-flow: row wrap;
  max-width: 1920px !important;

  @media all and (min-width: 640px) {
    .aside {
      flex: 1 auto;
    }
  }

  @media all and (min-width: 768px) {
    .editor_content {
      flex: 2 0px;
    }
  }

  .aside-left,
  .aside-right {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }

  .left-sidebar {
    opacity: 1;
    position: absolute;
    &.mobile {
      transition: 0.6s;
      -webkit-transition: 0.6s;
      top: calc(100vh - 140px);
    }

    &.hide {
      opacity: 0;
    }

    &.loaded {
      top: calc(100vh - 600px);
      z-index: 0;
    }
  }
  
  .left-sidebar.border-rad-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .right-sidebar {
    right: -325px;
    position: absolute;

    &.mobile {
      transition: 0.4s;
      -webkit-transition: 0.4s;
    }

    &.loaded {
      right: 10px;
    }

    button {
      .symbol.caret {

        svg {
          fill: $white;
        }

        .closed {
          transform: rotate(180deg);
          transition: 0.1s;
        }

        .open {
          transform: rotate(0deg);
          transition: 0.1s;
        }
      }
    }
  }

  .aside-left {
    .symbol.caret {

      width: 24px;
      height: 24px;

      svg {
        fill: var(--base-color);
      }

      .closed {
        transform: rotate(270deg);
        transition: 0.1s;
      }

      .open {
        transform: rotate(90deg);
        transition: 0.1s;
      }
    }
  }
}
