@import '@root/_variables';

.left_action {

    .hit_detail_box {
        display: flex;
        height: 30px;
        font-family: $font-murecho;
        font-weight: $font-weight-murecho;
        font-size: 11px;
        color: $base-text-color;

        &.mobile {
            height: 60px;
            line-height: 50px;
            margin-bottom: 18px !important;
    
            span {
                font-size: 14px;
                text-align: left;
            }
        }
    }

    .removal {
        transition: 0.5s all ease-out;
    }

    .transition-translate-x-enter {
        opacity: 0;
        transform: translateX(-100px);
    }
    .transition-translate-x-enter-active {
        opacity: 1;
        transform: translateX(0px);
        transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }
    .transition-translate-x-exit {
        opacity: 1;
        transform: translateX(0px);
    }
    .transition-translate-x-exit-active {
        opacity: 0;
        transform: translateX(-100px);
        transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    }

    .hit_detail_bar {
        height: auto;
        margin-right: 1px;
        padding-left: 5px;
        padding-top: 1px;
        padding-bottom: 5px;
    }

    .hit_detail_text {
        list-style: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -khtml-user-select: none;
        -webkit-touch-callout: none;
        height: auto;
        background-color: $hit-list-color;
        padding: 5px 14px;
        width: 100%;

        &:after {
            content: "\A";
            white-space: pre;
        }

        &:hover {
            cursor: pointer;
            transition: background .4s ease-out;
        }
    }

    .hit_detail_bar_not,
    .hit_detail_bar_not_compound {
        border-color: $not-gendered-orange;
        background-color: $not-gendered-orange;
    }

    // .hit_detail_not.active,
    // .hit_detail_not_compound.active,
    // .hit_detail_not:hover,
    // .hit_detail_not_compound:hover {
    //     border-color: $not-gendered-hover;
    //     background-color: $not-gendered-hover;
    // }

    .hit_detail_not.active,
    .hit_detail_not_compound.active,
    .hit_detail_not:hover,
    .hit_detail_not_compound:hover {
        border-color: $correct-gendered-green;
        background-color: $correct-gendered-green;
    }


    .hit_detail_bar_wrong {
        border-color: $wrong-gendered-red;
        background-color: $wrong-gendered-red;
    }

    .hit_detail_wrong.active,
    .hit_detail_wrong:hover {
        border-color: $wrong-gendered-hover;
        background-color: $wrong-gendered-hover;
    }

    .hit_detail_bar_correct,
    .hit_detail_bar_positive {
        border-color: $correct-gendered-green;
        background-color: $correct-gendered-green;
    }

    .hit_detail_correct.active,
    .hit_detail_positive.active,
    .hit_detail_correct:hover,
    .hit_detail_positive:hover {
        border-color: $correct-gendered-hover;
        background-color: $correct-gendered-hover;
    }
}