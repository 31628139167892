@import '@root/_variables';

.ignored_list {
    li {
        overflow: hidden;
        transition: background 150ms ease 0ms, border-color 150ms ease 0ms, box-shadow 150ms ease 0ms;
        background: $lightgrey;
        box-shadow: none;
        padding: 10px 12px;
        font-size: 13px;
        /* white-space: break-spaces; */

        &:hover {
            background: $base-color-background;
        }
    }
    .ignore_list_empty {
        font-size: 13px;
    }

    .ignore_word_input {
        font-size: 13px;
        font-family: $font-murecho;
        border: 1px solid var(--base-color);
        outline: none;
        user-select: text;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .symbol.cross {
        width: 8px;
        height: 8px;
    }
}

.ignore_list {
    .simple_text {
        font-size: 13px !important;
    }

    button.button.custom {
        height: 32px !important;
        line-height: 32px !important;
        padding: 0px;
        border-radius: 5px;
    }

    .symbol.ignore {
        margin-right: 8px;
        margin-top: 7px;
        width: 16px;
        height: 16px;
        svg {
            fill: $white;
        }
    }
}