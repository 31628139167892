@import '@root/_variables';

.hit_level_box {
    svg {
        width: 16px;
        height: 20px;
    }

    &.not_gendered {
        background-color: $not-gendered-orange;
    }

    &.correct_gendered {
        background-color: var(--base-color);
    }

    &.wrong_gendered {
        background-color: $wrong-gendered-red;
    }
}