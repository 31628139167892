@import '@root/_variables';

.editor_page_div {

    .editor_shell.mobile {
        .editor_container > div[contenteditable=true] {
            min-height: calc(100vh - 300px);
        }
    }

    .editor_shell {
        background: $white;
        height: auto;
        min-height: calc(100vh - 135px);
        .editor_container > div[contenteditable=true] {
            outline: none;
            padding: 15px;
            z-index: 1;
            min-height: calc(100vh - 135px);
            font-size: 14px;

            .lexical-paragraph {
                margin: 0 0 15px 0;
                position: relative;
            }
            
            hr {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            ol {
                list-style-type: decimal;
            }
            ol ol {
                list-style-type: upper-alpha;
            }
            ol ol ol {
                list-style-type: lower-alpha;
            }
            ol ol ol ol {
                list-style-type: upper-roman;
            }
            ol ol ol ol ol {
                list-style-type: lower-roman;
            }
            ul {
                list-style-type: disc;
            }
            ul ul {
                list-style-type: circle;
            }
            ul ul ul {
                list-style-type: square;
            }
            .lexical-ul {
                list-style-position: inside;
            }
            .lexical-listitem {
                display: list-item;
            }
            .lexical-nest-listitem {
                list-style-type: none;
            }
            .lexical-bold {
                font-weight: bold;
            }
            .lexical-italic {
                font-style: italic;
            }
            .lexical-strikethrough {
                text-decoration: line-through;
            }
            .lexical-quote {
                margin-left: 20px;
                font-size: var(--text-sm);
                border-left-color: #ced0d4;
                border-left-width: 4px;
                border-left-style: solid;
                padding-left: 16px;
            }
            .lexical-h1,
            .lexical-h2,
            .lexical-h3,
            .lexical-h4,
            .lexical-h5,
            .lexical-h6 {
                padding: 0;
            }
            .lexical-h1 {
                font-size: 24px;
                font-weight: 400;
                margin: 0 0 12px;
            }
            .lexical-h2 {
                font-size: 18px;
                font-weight: 700;
                margin: 10px 0 0;
            }
            .lexical-h3 {
                font-size: 12px;
                margin: 10px 0 0;
            }
            .lexical-h4,
            .lexical-h5,
            .lexical-h6 {
                font-weight: bold;
            }
            .lexical-h5 {
                font-size: 0.83em;
            }
            .lexical-h6 {
                font-size: 0.67em;
            }
            .lexical-link {
                text-decoration: none;
            }
            .lexical-link:hover {
                text-decoration: underline;
            }
            .lexical-code {
                background-color: #f0f2f5;
                font-family: Menlo, Consolas, Monaco, monospace;
                display: block;
                padding: 8px;
                line-height: 1.53;
                font-size: 14px;
                margin: 8px 0;
                tab-size: 2;
                overflow-x: auto;
                position: relative;
            }
            .lexical-holylight {
                background-color: $base-color-background;
                width: max-content;
                transition: background .5s ease-out;

                &:hover,
                &.active {
                    background-color: $base-color;
                }

                &.ignore {
                    background-color: $white;
                    &:hover {
                        background-color: $grey;
                    }
                }
            }
        }

        .editor_container > div.placeholder {
            position: absolute;
            margin-top: -30px;
            margin-left: 5px;
            z-index: 0;
            display: none;
        }
    }
}
