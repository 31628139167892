@import '@root/_variables';

.left_sidebar {
    .left_info {
        p {
            font-family: $font-murecho;
            color: $text-color;
            text-align: left;
            font-size: 13px;
        }
    }

    &.subtitle {
        background-color: $transparent;
        font-family: $font-murecho;
        font-weight: 400;
        color: var(--base-color);
        text-align: left;
        line-height: 30px;
        font-size: 14px;
    }
}