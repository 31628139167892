@import '@root/_variables';

.nav_div {
    ul {
        list-style: none;

        li {
            display: inline;
            font-size: 14px;
            height: 15px;
            background-color: $transparent;
            text-align: center;
            line-height: 14px;

            a {
                cursor: pointer;
                font-family: $font-prompt;
                font-weight: $font-weight-prompt;
                color: var(--base-text-color);
                opacity: 0.7;

                &:hover {
                    opacity: 1.0;
                }
            }
        }
    }
}

.hide_menu_nav {
    display: none;
}

.show_menu_nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.symbol.finnit_nav_menu {
    svg {
        fill: $white;
        stroke: var(--base-color);
    }
}