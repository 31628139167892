@import '@root/_variables';

svg.disabled {
    cursor: none !important;
    pointer-events: none !important;
}

.editor_toolbar {
    background-color: $white;

    .undo_button {
        margin-left: 16px;
    }

    .copy_button,
    .clear_button {
        margin-right: 20px;
    }

    .undo_button,
    .redo_button {
        margin-right: 24px;
    }

    .redo_button:active,
    .undo_button:active,
    .clear_button:active,
    .copy_button:active {
        svg {
            fill: red;
        }
    }

    .redo_button,
    .undo_button,
    .clear_button,
    .copy_button,
    .mic_button {
        .svg_container {
            padding: 4px;
            border-radius: 5px;

            &.enabled {
                &:hover {
                    background-color: $lightgrey;
                }

                &:active,
                &:focus {
                    svg {
                        fill: var(--base-color);
                    }
                }
            }
        }
        svg {
            width: 22px;
            height: 22px;
            fill: $base-text-color;
        }

        svg:hover {
            cursor: pointer;
        }
    }

    .mic_button svg {
        width: 20px;
        height: 20px;
    }

    .symbol.finnit_checks {
        width: 25px;
        height: 25px;
        svg {
            fill: var(--base-color);
        }
    }

    .symbol.finnit_nav_menu {
        svg {
            fill: var(--base-color);
        }
    }
}

.editor_toolbar-after {
    background: $body-background;
    height: 15px;
    width: 100%;
}