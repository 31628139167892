@import '@root/_variables';


.right_sidebar {
    button {
        .symbol.caret {
            width: 6px;
            height: 10px;

            svg {
                fill: $white;
            }

            .closed {
                transform: rotate(0deg);
                transition: 0.2s;
            }

            .open {
                transform: rotate(90deg);
                transition: 0.2s;
            }
        }
    }

    .theme_mode {
        box-sizing: border-box;
        font-family: $font-prompt;
        color: $white;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    .standard {
        background-color: $base-color;
    }

    .high_contrast {
        background-color: $high-contrast-theme;
    }

    .right_sidebar_box {
        background: $white;
    }

    .symbol.finnit_nav_cross {
        svg {
            fill: var(--base-color);
        }
    }
}